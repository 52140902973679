<template>
  <div class="forget_container">
    <div class="top_box">
      <el-tabs v-model="activeName">
        <el-tab-pane
          label="忘记密码"
          name="first"
        >
          <el-form
            ref="form"
            :model="form"
            :rules="rules"
          >
            <el-form-item
              label=""
              prop="mobile"
            >
              <el-input
                v-model="form.mobile"
                placeholder="手机号"
                prefix-icon="el-icon-mobile-phone"
              ></el-input>
            </el-form-item>

            <el-form-item
              label=""
              prop="sms_code"
            >
              <div class="code_img_box">
                <el-input
                  v-model="form.sms_code"
                  placeholder="验证码"
                  prefix-icon="el-icon-circle-check"
                ></el-input>
                <div class="code_img">
                  <el-button
                    :disabled="disableCodeBtn"
                    type="primary"
                    @click="getPhoneCode"
                  >{{codeBtnText}}</el-button>
                </div>
              </div>
            </el-form-item>

            <el-form-item
              label=""
              prop="user_pass"
            >
              <el-input
                show-password
                v-model="form.user_pass"
                placeholder="密码"
                prefix-icon="el-icon-lock"
              ></el-input>
            </el-form-item>
            <el-form-item
              label=""
              prop="user_pass_repeat"
            >
              <el-input
                show-password
                v-model="form.user_pass_repeat"
                placeholder="确认密码"
                prefix-icon="el-icon-lock"
              ></el-input>
            </el-form-item>

            <el-button
              type="primary"
              @click="clickConfim"
            >确 定</el-button>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="bottom_box">
      <p @click="$emit('changeBox',1)">去登录</p>
      <p></p>
    </div>
  </div>
</template>

<script>
import { forgetPwdApi } from '../../api/login'

import { getSmsCodeApi } from '../../api/common'
export default {
  // 组件名称
  name: '',
  // 局部注册的组件
  components: {},
  // 组件参数 接收来自父组件的数据
  props: {},
  // 组件状态值
  data () {
    var validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else {
        var pattern = /^(1[3|4|5|7|8|9][0-9])\d{8}$/
        // this.disabledBtn = !pattern.test(this.mobile)
        if (pattern.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确手机号'))
        }
      }
    }
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.form.user_pass_repeat !== '') {
          this.$refs.form.validateField('user_pass_repeat')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.user_pass) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      timer: null,
      disableCodeBtn: false,
      codeBtnText: '获取验证码',
      activeName: 'first',
      form: {
        mobile: '',
        sms_code: '',
        user_pass: '',
        user_pass_repeat: ''
      },
      rules: {
        mobile: [
          { validator: validatePhone, trigger: 'blur' }
        ],
        user_pass: [
          { validator: validatePass, trigger: 'blur' }
        ],
        user_pass_repeat: [
          { validator: validatePass2, trigger: 'blur' }
        ],
        sms_code: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      }
    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  created () { },
  mounted () { },
  // 组件方法
  methods: {
    getPhoneCode () {
      this.$refs.form.validateField('mobile', async (valid) => {
        console.log(valid)
        if (valid) return
        this.disableCodeBtn = true
        const res = await getSmsCodeApi({
          mobile: this.form.mobile,
          type: 4
        })
        if (res && res.code === 0) {
          // console.log(123)
          this.codeBtnText = 60
          this.timer = setInterval(() => {
            console.log(this.codeBtnText)
            this.codeBtnText = this.codeBtnText - 1
            if (+this.codeBtnText === 0) {
              clearInterval(this.timer)
              this.codeBtnText = '获取验证码'
              this.disableCodeBtn = false
            }
          }, 1000)
        } else {
          this.disableCodeBtn = false
        }
      })
    },
    clickConfim () {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return
        const res = await forgetPwdApi(this.form)
        if (res && res.code === 0) {
          this.$message.success('修改成功')
          this.$emit('changeBox', 1)
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.forget_container {
  height: 100%;
  width: 100%;
  .top_box {
    height: 85%;
    border-bottom: 1px solid #a8b0ca;
    .el-form {
      width: 90%;
      margin: 0 auto;
      // margin-top: 10px;

      .el-button {
        width: 100%;
      }

      .code_img_box {
        display: flex;
        justify-content: space-between;
        .el-input {
          width: 60%;
        }
        .code_img {
          width: 35%;
          // background-color: pink;
        }
      }

      /deep/.el-input__inner {
        padding-left: 40px;
        color: #fff;
        background-color: transparent;
        &:focus {
          border-color: #cfd2da;
        }
      }
      /deep/ .el-input__icon {
        font-size: 20px;
      }
    }
  }
  .bottom_box {
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    font-size: 14px;
    padding: 0 15px;
    p {
      user-select: none;
      cursor: pointer;
    }
  }

  /deep/.el-tabs__item {
    color: #a7a8ad;
  }
  /deep/.el-tabs__item.is-active {
    color: #fff;
  }
  /deep/.el-tabs__active-bar {
    background-color: #fff;
  }
  /deep/.el-tabs__nav-wrap::after {
    background-color: transparent;
  }
}
</style>
