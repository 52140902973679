import request from './request'

// 注册

export function registerApi (data) {
  return request({
    url: '/client/register',
    method: 'POST',
    data
  })
}

// 图片验证码
export function getCaptchaImgApi () {
  return request({
    url: '/client/captcha',
    method: 'GET'
    // responseType: 'blob'
  })
}

export function loginByAccountApi (data) {
  return request({
    url: '/client/loginByAccount',
    method: 'POST',
    data
  })
}

export function loginByPhoneApi (data) {
  return request({
    url: '/client/loginBySmsCode',
    method: 'POST',
    data
  })
}

export function loginOutApi (data) {
  return request({
    url: '/client/logout',
    method: 'POST',
    data
  })
}

export function forgetPwdApi (data) {
  return request({
    url: '/client/forgetPassword',
    method: 'POST',
    data
  })
}
