import request from './request'

// 注册

export function getSmsCodeApi (data) {
  return request({
    url: '/client/sendSms',
    method: 'POST',
    data
  })
}

export function uploadFileApi (data) {
  return request({
    url: '/client/upload',
    method: 'POST',
    data
  })
}
